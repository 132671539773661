import React from "react";
import {Link} from "react-router-dom";

export default function Nav(props) {    
    return (
        <nav>
            <div className="nav-items">
                <Link to="/">Leaderboards</Link>
                <Link to="/" className="logo">The Redev Times</Link>
                <Link to="/crosswords">Past Crosswords</Link>
            </div>
        </nav>
    );
}