import React, {useState} from "react";
import Leaderboards from "./pages/leaderboards/Leaderboards";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import Nav from "./components/nav";
import Crosswords from "./pages/crosswords/Crosswords";
import ThemeSwitch from "./components/themeSwitch";

export default function App() {
    const today = new Date();
    
    return (
        <>
            <BrowserRouter>
                <Nav/>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Leaderboards />} />
                        <Route path="/crosswords" element={<Navigate to={`/crosswords/${today.getFullYear()}-${(today.getMonth()+1).toString().padStart(2, "0")}-${(today.getDate()-1).toString().padStart(2, "0")}`}/>} />
                        <Route path="/crosswords/:date" element={<Crosswords />} />
                    </Routes>
                </div>
                <ThemeSwitch/>
            </BrowserRouter>
        </>
    );
}