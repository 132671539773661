import React, {useEffect, useState} from 'react';
import Cookies from "universal-cookie";

export default function ThemeSwitch(props) {
    const cookies = new Cookies();
    let [themeChecked, setThemeChecked] = useState(false);

    function changeTheme(event) {
        if (event.target.checked) {
            document.querySelector("body").className = "dark-theme";
            cookies.set("redev-nyt-theme", "dark-theme", { path: '/', expires: new Date(Date.now() + (10 * 365 * 24 * 60 * 60 * 1000)) });
            setThemeChecked(true);
        } else {
            document.querySelector("body").className = "light-theme";
            cookies.set("redev-nyt-theme", "light-theme", { path: '/', expires: new Date(Date.now() + (10 * 365 * 24 * 60 * 60 * 1000)) });
            setThemeChecked(false);
        }
    }

    useEffect(() => {
        let cookieTheme = cookies.get('redev-nyt-theme');
        if (cookieTheme) {
            document.querySelector("body").className = cookieTheme;
            if (cookieTheme === "dark-theme")
                setThemeChecked(true);
        }
    }, []);
    
    return (
        <label className="theme-switch">
            <input checked={themeChecked} id="themeCheckbox" type="checkbox" onChange={(event) => changeTheme(event)}/>
            <span className="slider round"></span>
        </label>
    );
}