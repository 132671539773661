// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/karnakcondensed-normal-700.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Chomsky.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html {\n  font-size: 10px;\n}\n\n@media (min-width: 1024px) {\n  html {\n    font-size: 12px;\n  }\n}\n\n@media (min-width: 1440px) {\n  html {\n    font-size: 14px;\n  }\n}\n\n@media (min-width: 1920px) {\n  html {\n    font-size: 16px;\n  }\n}\n\n* {\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: \"Libre Franklin\", sans-serif;\n  background-color: var(--color-secondary);\n}\n\n@font-face {\n  font-family: \"KarnakCondensed\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: \"Chomsky\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,yCAAyC;EACzC,wCAAwC;AAC1C;;AAEA;EACE,8BAA8B;EAC9B,4CAA2D;AAC7D;;AAEA;EACE,sBAAsB;EACtB,4CAAsC;AACxC","sourcesContent":["html {\n  font-size: 10px;\n}\n\n@media (min-width: 1024px) {\n  html {\n    font-size: 12px;\n  }\n}\n\n@media (min-width: 1440px) {\n  html {\n    font-size: 14px;\n  }\n}\n\n@media (min-width: 1920px) {\n  html {\n    font-size: 16px;\n  }\n}\n\n* {\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: \"Libre Franklin\", sans-serif;\n  background-color: var(--color-secondary);\n}\n\n@font-face {\n  font-family: \"KarnakCondensed\";\n  src: url(\"./assets/fonts/karnakcondensed-normal-700.woff2\");\n}\n\n@font-face {\n  font-family: \"Chomsky\";\n  src: url(\"./assets/fonts/Chomsky.otf\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
