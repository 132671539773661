import React from "react";
import PodiumIcon from "./podiumIcon";
import {getFormattedTime} from "../helpers/dateTimeFormatter";

export default function Podium(props) {
    if (props.data.length === 0)
        return;
    
    return (
        <div className="podium">
            <PodiumIcon player={props.data[1]?.player} score={props.isTime ? getFormattedTime(props.data[1]?.score) : props.data[1]?.score}/>
            <PodiumIcon player={props.data[0]?.player} score={props.isTime ? getFormattedTime(props.data[0]?.score) : props.data[0]?.score}/>
            <PodiumIcon player={props.data[2]?.player} score={props.isTime ? getFormattedTime(props.data[2]?.score) : props.data[2]?.score}/>
        </div>
    );
}