import React, {useEffect, useState} from "react";
import LeaderboardRow from "./leaderboardRow";
import {getFormattedTime} from "../helpers/dateTimeFormatter";

export default function Leaderboard(props) {
    const [data, setData] = useState(null);
    
    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    
    if (!data)
        return;

    return (
        <div className="leaderboard-container">
            {data.map((leaderboardRow, placement) => (<LeaderboardRow
                key={`${placement}-${leaderboardRow.player}-${leaderboardRow.score}`}
                placement={placement + 1}
                player={leaderboardRow.player}
                score={props.isTime ? getFormattedTime(leaderboardRow.score) : leaderboardRow.score}
                date={leaderboardRow.date}
                showContextMenu={props.showContextMenu}
            />))}
        </div>
    );
}