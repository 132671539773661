import React, {useEffect, useState} from "react";
import Leaderboard from "./leaderboard.js";
import LeaderboardRow from "./leaderboardRow";
import {getFormattedTime} from "../helpers/dateTimeFormatter";
import StatsGraph from "./statsGraph";

export default function PlayerProfileModal(props) {
    const [weeklyData, setWeeklyData] = useState(null);
    const [fastestData, setFastestData] = useState(null);
    const [slowestData, setSlowestData] = useState(null);
    const [weeklyOrderedData, setWeeklyOrderedData] = useState([]);
    const [placements, setPlacements] = useState([]);
    
    useEffect(() => {
        if (!props.player)
            return;

        // Update modal data
        fetch("/api/player/week?player=" + props.player)
            .then(response => response.json())
            .then((d) => {
                setWeeklyData(d);
            })
        
        fetch(`/api/player/week?player=${props.player}&sortField=dateObject&sortDirection=ascending`)
            .then(response => response.json())
            .then((d) => {
                setWeeklyOrderedData(d);
            })

        fetch("/api/player/fastest?player=" + props.player)
            .then(response => response.json())
            .then((d) => {
                setFastestData(d);
            })

        fetch("/api/player/slowest?player=" + props.player)
            .then(response => response.json())
            .then((d) => {
                setSlowestData(d);
            })
    }, []);
    
    useEffect(() => {
        // Cannot directly update placements as multiple requests try updating it at once meaning that only one is taken.
        let responseCount = 0;
        const tempPlacements = []
        
        const addPlacement = (d, leaderboardPhrase) => {
            responseCount++;
            
            const position = d.map(function(e) { return e.player; }).indexOf(props.player);
            if (position > -1)
                tempPlacements.push(`#${position+1} ${leaderboardPhrase}`)
            
            if (responseCount === 7)
                setPlacements([...tempPlacements]);
        }
        
        fetch("/api/leaderboard/today").then(response => response.json()).then((d) => {
            addPlacement(d, "on Today's Leaderboard");
        });

        fetch("/api/leaderboard/weekly").then(response => response.json()).then((d) => {
            addPlacement(d, "on Weekly Leaderboard");
        });

        fetch("/api/leaderboard/fastest").then(response => response.json()).then((d) => {
            addPlacement(d, "on Fastest Leaderboard");
        });

        fetch("/api/leaderboard/slowest").then(response => response.json()).then((d) => {
            addPlacement(d, "on Slowest Leaderboard");
        });

        fetch("/api/leaderboard/average").then(response => response.json()).then((d) => {
            addPlacement(d, "on All Time Average Leaderboard");
        });

        fetch("/api/leaderboard/played").then(response => response.json()).then((d) => {
            addPlacement(d, "on Games Played Leaderboard");
        });

        fetch("/api/leaderboard/wins/daily").then(response => response.json()).then((d) => {
            addPlacement(d, "on Daily Wins Leaderboard");
        });
    }, [props.player]);
    
    return (
        <div id="playerModal" className={props.visible ? "visible" : ""}>
            <div className="modal-content">
                <a className="close-btn" onClick={props.hideModal}>X</a>
                <div className="player-summary">
                    <div className="player-summary-header">
                        <div>
                            <div className="player-profile-icon"></div>
                            <h2 id="profileHeaderName">{props.player}</h2>
                        </div>
                    </div>
                    <div className="player-summary-body">
                        <h3>Placements</h3>
                        <div id="leaderboardPositions">
                            {
                                placements.map((placement) => (
                                    <p key={placement}>{placement}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="player-stats">
                    <h2><span id="playerStatsName">{props.player}</span>'s Statistics:</h2>
                    <div className="stats-container">
                        <div>
                            <h3>This weeks times...</h3>
                            <Leaderboard
                                key={weeklyData}
                                data={weeklyData}
                                isTime={true}
                                showContextMenu={props.enableContextMenu}
                            />
                        </div>
                        <div>
                            <h3>This weeks times visualised...</h3>
                            <div id="statsWeekChart" className="stats-chart">
                                <StatsGraph data={weeklyOrderedData}/>
                            </div>
                        </div>
                        <div>
                            <h3>Fastest times...</h3>
                            <Leaderboard
                                key={fastestData}
                                data={fastestData}
                                isTime={true}
                                showContextMenu={props.enableContextMenu}
                            />
                        </div>
                        <div>
                            <h3>Slowest times...</h3>
                            <Leaderboard
                                key={slowestData}
                                data={slowestData}
                                isTime={true}
                                showContextMenu={props.enableContextMenu}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}