import React from "react";
import {useNavigate} from "react-router-dom";

export default function ContextMenu(props) {
    const navigate = useNavigate();
    
    return (
        <div id="contextMenu" className={props.visible ? "context-menu visible" : "context-menu"} style={{left: props.position.x + "px", top: props.position.y + "px"}}>
            <div onClick={props.showProfile}>View Player Profile</div>
            <div id="viewCrossword" onClick={props.viewCrossword} style={{display: props.date ? "block" : "none"}}>View Crossword</div>
        </div>
    );
}