import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import '../../components.css'
import Leaderboard from '../../components/leaderboard'
import Podium from "../../components/podium";
import PlayerProfileModal from "../../components/playerProfileModal";
import ContextMenu  from "../../components/contextMenu";

export default function LeaderboardPage() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    
    const [leaderboardToday, setLeaderboardToday] = useState([]);
    const [leaderboardWeekly, setLeaderboardWeekly] = useState([]);
    const [leaderboardFastest, setLeaderboardFastest] = useState([]);
    const [leaderboardSlowest, setLeaderboardSlowest] = useState([]);
    const [leaderboardAverage, setLeaderboardAverage] = useState([]);
    const [leaderboardPlayed, setLeaderboardPlayed] = useState([]);
    const [leaderboardWins, setLeaderboardWins] = useState([]);
    
    const [selectedPlayer, setSelectedPlayer] = useState("");
    const [selectedCrosswordDate, setSelectedCrosswordDate] = useState("");
    const [showPlayerProfile, setShowPlayerProfile] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({x: 0, y: 0});

    const [layout, setLayout] = useState(cookies.get("redev-nyt-layout") ?? "old");

    useEffect(() => {
        fetch("/api/leaderboard/update")
            .then(response => response.json())
            .then(() => {
                fetch("/api/leaderboard/today")
                    .then(response => response.json())
                    .then(data => {setLeaderboardToday(data)})

                fetch("/api/leaderboard/weekly")
                    .then(response => response.json())
                    .then(data => {setLeaderboardWeekly(data)})

                fetch("/api/leaderboard/fastest?count=7")
                    .then(response => response.json())
                    .then(data => {setLeaderboardFastest(data)})

                fetch("/api/leaderboard/slowest")
                    .then(response => response.json())
                    .then(data => {setLeaderboardSlowest(data)})

                fetch("/api/leaderboard/average")
                    .then(response => response.json())
                    .then(data => {setLeaderboardAverage(data)})

                fetch("/api/leaderboard/played")
                    .then(response => response.json())
                    .then(data => {setLeaderboardPlayed(data)})

                fetch("/api/leaderboard/wins/daily")
                    .then(response => response.json())
                    .then(data => {setLeaderboardWins(data)})
            })
    }, []);

    const initLeaderboard = (firstRender = false) => {
        const carouselItems = document.querySelectorAll(".carousel-item");
        
        if (carouselItems.length === 0)
            return;
        
        // Ensure Today's leaderboard is shown first
        if (window.innerWidth < 768) {
            carouselItems[0].dataset.position = "4";
            for (let i = 1; i < carouselItems.length; i++) {
                carouselItems[i].dataset.position = (i-3).toString(); 
            }
        }
        
        for (let carouselItem of carouselItems) {                
            carouselItem.style.left = (getCarouselDiff() * carouselItem.dataset.position).toString() + "%";
        }
    }

    const nextLeaderboard = () =>  {
        const carouselItems = document.querySelectorAll(".carousel-item");
        for (let carouselItem of carouselItems) {
            const newPosition = carouselItem.dataset.position < carouselItems.length - 3 ? parseInt(carouselItem.dataset.position) + 1 : -2;
            carouselItem.dataset.position = newPosition.toString();
            carouselItem.style.left = (getCarouselDiff() * newPosition).toString() + "%";

        }
    }

    const prevLeaderboard = () => {
        const carouselItems = document.querySelectorAll(".carousel-item");
        for (let carouselItem of carouselItems) {
            const newPosition = carouselItem.dataset.position > -2 ? parseInt(carouselItem.dataset.position) - 1 : carouselItems.length - 3;
            carouselItem.dataset.position = newPosition.toString();
            carouselItem.style.left = (getCarouselDiff() * newPosition).toString() + "%";
        }
    }

    const getCarouselDiff = () => {
        return window.innerWidth < 768 ? 100 : (100 / 3)
    }

    const showProfile = () => {
        setShowPlayerProfile(true);
        setShowContextMenu(false);
    }

    const hidePlayerProfile = () => {
        setShowPlayerProfile(false);
    }

    const enableContextMenu = (event, player, date = null) => {
        setContextMenuPosition({x: event.clientX, y: event.clientY});
        setShowContextMenu(true);
        setSelectedPlayer(player);
        setSelectedCrosswordDate(date);
    }

    const hideContextMenu = (event) => {
        if (showContextMenu) {
            const contextMenu = document.getElementById("contextMenu");
            if (event.target.offsetParent && event.target.offsetParent !== contextMenu && !event.target.offsetParent.classList.contains("btn-leaderboard-options")) {
                setShowContextMenu(false);
            }
        }
    }
    
    const viewCrossword = () => {
        navigate(`/crosswords/${reformatDate(selectedCrosswordDate)}`);
    }
    
    const reformatDate = (date) => {
        let dateParts = date.split("-");
        return `${dateParts[2]}-${dateParts[1].padStart(2, "0")}-${dateParts[0].padStart(2, "0")}`;
    }
    
    const updateAverageFilter = (timespan) => {
        fetch("/api/leaderboard/average?timespan=" + timespan)
            .then(response => response.json())
            .then(data => {setLeaderboardAverage(data)})
    }
    
    useEffect(() => {
        initLeaderboard();
    });

    cookies.addChangeListener(({name, value, options}) => {
        if (name === "redev-nyt-layout") {
            setLayout(value);
        }
    });

    return (
        layout === "new" ?
        (
            <main onClick={(e) => hideContextMenu(e)}>
                <div className="carousel">
                    <span onClick={nextLeaderboard} className="carousel-button left">
                    </span>
                    <div className="leaderboards-container">
                        <div className="carousel-item" data-position="-2">
                            <div className="leaderboard-card">
                                <h1>Games Played</h1>
                                <Podium
                                    key={"podium-played-" + leaderboardPlayed}
                                    data={leaderboardPlayed}
                                    isTime={false}
                                />
                                <Leaderboard
                                    key={"leaderboard-played-" + leaderboardPlayed}
                                    data={leaderboardPlayed}
                                    isTime={false}
                                    showContextMenu={enableContextMenu}
                                />
                            </div>
                        </div>
                        <div className="carousel-item" data-position="-1">
                            <div className="leaderboard-card">
                                <h1>Daily Wins</h1>
                                <Podium
                                    key={"podium-daily-" + leaderboardWins}
                                    data={leaderboardWins}
                                    isTime={false}
                                />
                                <Leaderboard
                                    key={"leaderboard-daily-" + leaderboardWins}
                                    data={leaderboardWins}
                                    isTime={false}
                                    showContextMenu={enableContextMenu}
                                />
                            </div>
                        </div>
                        <div className="carousel-item" data-position="0">
                            <div className="leaderboard-card">
                                <h1>Average Times</h1>
                                <Podium
                                    key={"podium-average-" + leaderboardAverage}
                                    data={leaderboardAverage}
                                    isTime={true}
                                />
                                <div className="leaderboard-filter">
                                    <select defaultValue="all" onChange={(event) => updateAverageFilter(event.target.value)}>
                                        <option value="all">All Time</option>
                                        <option value="weekly">Last 7 Days</option>
                                        <option value="monthly">Last 30 Days</option>
                                        <option value="yearly">Last 365 Days</option>
                                    </select>
                                </div>
                                <Leaderboard
                                    key={"leaderboard-average-" + leaderboardAverage}
                                    data={leaderboardAverage}
                                    isTime={true}
                                    showContextMenu={enableContextMenu}
                                />
                            </div>
                        </div>
                        <div className="carousel-item" data-position="1">
                            <div className="leaderboard-card">
                                <h1>Today's Leaderboard</h1>
                                <Podium
                                    key={"podium-today-" + leaderboardToday}
                                    data={leaderboardToday}
                                    isTime={true}
                                />
                                <a className="btn" href="https://www.nytimes.com/crosswords/game/mini">Play Game</a>
                                <Leaderboard
                                    key={"leaderboard-today-" + leaderboardToday}
                                    data={leaderboardToday}
                                    isTime={true}
                                    showContextMenu={enableContextMenu}
                                />
                            </div>
                        </div>
                        <div className="carousel-item" data-position="2">
                            <div className="leaderboard-card">
                                <h1>Weekly Leaderboard</h1>
                                <Podium
                                    key={"podium-weekly-" + leaderboardWeekly}
                                    data={leaderboardWeekly}
                                    isTime={true}
                                />
                                <Leaderboard
                                    key={"leaderboard-weekly-" + leaderboardWeekly}
                                    data={leaderboardWeekly}
                                    isTime={true}
                                    showContextMenu={enableContextMenu}
                                />
                            </div>
                        </div>
                        <div className="carousel-item" data-position="3">
                            <div className="leaderboard-card">
                                <h1>Fastest Times</h1>
                                <Podium
                                    key={"podium-fastest-" + leaderboardFastest}
                                    data={leaderboardFastest}
                                    isTime={true}
                                />
                                <Leaderboard
                                    key={"leaderboard-fastest-" + leaderboardFastest}
                                    data={leaderboardFastest}
                                    isTime={true}
                                    showContextMenu={enableContextMenu}
                                />
                            </div>
                        </div>
                        <div className="carousel-item" data-position="4">
                            <div className="leaderboard-card">
                                <h1>Slowest Times</h1>
                                <Podium
                                    key={"podium-slowest-" + leaderboardSlowest}
                                    data={leaderboardSlowest}
                                    isTime={true}
                                />
                                <Leaderboard
                                    key={"leaderboard-slowest-" + leaderboardSlowest}
                                    data={leaderboardSlowest}
                                    isTime={true}
                                    showContextMenu={enableContextMenu}
                                />
                            </div>
                        </div>
                    </div>
                    <span onClick={prevLeaderboard} className="carousel-button right"></span>
                </div>
                <PlayerProfileModal
                    key={`profile-visible-${showPlayerProfile}-${selectedPlayer}`}
                    visible={showPlayerProfile}
                    player={selectedPlayer}
                    hideModal={hidePlayerProfile}
                    enableContextMenu={enableContextMenu}
                />
                <ContextMenu
                    key={showContextMenu + contextMenuPosition.x + contextMenuPosition.y}
                    visible={showContextMenu}
                    position={contextMenuPosition}
                    date={selectedCrosswordDate}
                    showProfile={showProfile}
                    viewCrossword={viewCrossword}
                />
                <div className="switch-layout-text">
                    <a onClick={() => cookies.set('redev-nyt-layout', 'old', { path: '/', expires: new Date(Date.now() + (10 * 365 * 24 * 60 * 60 * 1000)) })}>Switch to old layout</a>
                </div>
            </main>
        )
        : 
        (
            <main onClick={(e) => hideContextMenu(e)}>
                <div className="old-leaderboard-container">
                    <div>
                        <div className="leaderboard-card">
                            <h1>Fastest Times</h1>
                            <Leaderboard
                                key={"leaderboard-fastest-" + leaderboardFastest}
                                data={leaderboardFastest}
                                isTime={true}
                                showContextMenu={enableContextMenu}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="leaderboard-card">
                            <h1>Today's Leaderboard</h1>
                            <a className="btn" href="https://www.nytimes.com/crosswords/game/mini">Play Game</a>
                            <Leaderboard
                                key={"leaderboard-today-" + leaderboardToday}
                                data={leaderboardToday}
                                isTime={true}
                                showContextMenu={enableContextMenu}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="leaderboard-card">
                            <h1>Slowest Times</h1>
                            <Leaderboard
                                key={"leaderboard-slowest-" + leaderboardSlowest}
                                data={leaderboardSlowest}
                                isTime={true}
                                showContextMenu={enableContextMenu}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="leaderboard-card">
                            <h1>Average Times</h1>
                            <div className="leaderboard-filter">
                                <select defaultValue="all" onChange={(event) => updateAverageFilter(event.target.value)}>
                                    <option value="all">All Time</option>
                                    <option value="weekly">Last 7 Days</option>
                                    <option value="monthly">Last 30 Days</option>
                                    <option value="yearly">Last 365 Days</option>
                                </select>
                            </div>
                            <Leaderboard
                                key={"leaderboard-average-" + leaderboardAverage}
                                data={leaderboardAverage}
                                isTime={true}
                                showContextMenu={enableContextMenu}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="leaderboard-card">
                            <h1>Weekly Leaderboard</h1>
                            <Leaderboard
                                key={"leaderboard-weekly-" + leaderboardWeekly}
                                data={leaderboardWeekly}
                                isTime={true}
                                showContextMenu={enableContextMenu}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="leaderboard-card">
                            <h1>Games Played</h1>
                            <Leaderboard
                                key={"leaderboard-played-" + leaderboardPlayed}
                                data={leaderboardPlayed}
                                isTime={false}
                                showContextMenu={enableContextMenu}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="leaderboard-card">
                            <h1>Daily Wins</h1>
                            <Leaderboard
                                key={"leaderboard-daily-" + leaderboardWins}
                                data={leaderboardWins}
                                isTime={false}
                                showContextMenu={enableContextMenu}
                            />
                        </div>
                    </div>
                </div>
                <PlayerProfileModal
                    key={`profile-visible-${showPlayerProfile}-${selectedPlayer}`}
                    visible={showPlayerProfile}
                    player={selectedPlayer}
                    hideModal={hidePlayerProfile}
                    enableContextMenu={enableContextMenu}
                />
                <ContextMenu
                    key={showContextMenu + contextMenuPosition.x + contextMenuPosition.y}
                    visible={showContextMenu}
                    position={contextMenuPosition}
                    date={selectedCrosswordDate}
                    showProfile={showProfile}
                    viewCrossword={viewCrossword}
                />
                <div className="switch-layout-text">
                    <a onClick={() => cookies.set('redev-nyt-layout', 'new', { path: '/', expires: new Date(Date.now() + (10 * 365 * 24 * 60 * 60 * 1000)) })}>Switch to new layout</a>
                </div>
            </main>
        )
    );
}