import React from "react";

export default function LeaderboardRow(props) {    
    return (
        <div className="leaderboard-row" onContextMenu={(event) => {event.preventDefault(); props.showContextMenu(event, props.player, props.date)}}>
            <p className="placement">{props.placement}</p>
            <div className="player-name">
                {props.player}
                <p className="date">{props.date}</p>
            </div>
            <p className="score">{props.score}</p>
            <div className="btn-leaderboard-options" onClick={(event) => {event.preventDefault(); props.showContextMenu(event, props.player, props.date)}}>
                <div><span></span></div>
            </div>
        </div>
    );
}