import React from "react";
import { JSCharting } from 'jscharting-react'

export default function StatsGraph(props) {
    let points = [];

    for (let time of props.data) {
        points.push({x: time.date, y: time.timeInSeconds});
    }
    
    const config = {
        legend: {
            visible: false
        },
        xAxis: {
            label: {
                text: "Date"
            }
        },
        yAxis: {
            label: {
                text: "Time (seconds)"
            }
        },
        series: [
            {
                points
            }
        ],
        title: {
            label: {
                text: "Weekly Times"
            }
        },
        box: {
            fill: "transparent",
        }
    }
    
    return (
        <JSCharting options={config} />
    );
}