import React, {useEffect, useState} from 'react';
import '../../components.css'
import {useParams, useNavigate} from "react-router-dom";

export default function CrosswordsPage(props) {
    const params = useParams();
    const date = params?.date;
    const navigate = useNavigate();
    
    const [crosswordSvg, setCrosswordSvg] = useState("");
    const [publicationDate, setPublicationDate] = useState("");
    const [acrossClues, setAcrossClues] = useState([]);
    const [downClues, setDownClues] = useState([]);

    useEffect(() => {
        setPublicationDate(date);
    }, [date, params]);
    
    useEffect(() => {        
        if (!publicationDate)
            return;
        
        fetch(`/api/leaderboard/preview?date=${publicationDate}`)
            .then(response => response.json())
            .then(data => {
                setCrosswordSvg(data.board);
                setAcrossClues(data.clues.filter((clue) => clue.direction === "Across"));
                setDownClues(data.clues.filter((clue) => clue.direction === "Down"));
            })
            .catch(err => {
                let crosswordPreview = document.getElementById("crosswordPreview");
                setCrosswordSvg("");
            })
    }, [publicationDate]);
    
    
    
    return (
        <div className="crossword-preview-container">
            <div className="crossword-filter">
                <h2>Preview Crossword...</h2>
                <input id="previewCrosswordDate" type="date" aria-label="Saved Crossword Date" value={publicationDate?.toString()} onChange={(e) => navigate(`/crosswords/${e.target.value}`)} />
            </div>
                {
                    new Date(date).setHours(0,0,0,0) === new Date().setHours(0,0,0,0) ?
                    (
                        <>
                            <p>No peeking!</p>
                            <p>Play today's crossword <a href="https://www.nytimes.com/crosswords/game/mini">here!</a></p>
                        </>
                    ) :
                    crosswordSvg === "" ? 
                    (
                        <p>No saved crossword for {publicationDate}</p>
                    ) :
                    (
                        <div id="crosswordPreview" className="crossword-preview">
                            <div className="crossword-board" dangerouslySetInnerHTML={{__html: crosswordSvg?.toString()}}></div>
                            <div className="crossword-clues">
                                <div className="crossword-clues-across">
                                    <h3>Across</h3>
                                    <hr/>
                                    {acrossClues.map((clue) => (
                                        <p key={clue.text[0]?.plain}>{clue.label}. {clue.text[0]?.plain}</p>
                                    ))}
                                </div>
                                <div className="crossword-clues-down">
                                    <h3>Down</h3>
                                    <hr/>
                                    {downClues.map((clue) => (
                                        <p key={clue.text[0]?.plain}>{clue.label}. {clue.text[0]?.plain}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                }
        </div>
    );
}