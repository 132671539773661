import React from "react";

export default function PodiumIcon(props) {
    return (
        <div className="podium-icon">
            <img alt={props.player} src={props.iconSrc}/>
            <p className="player-name">{props.player}</p>
            <p className="score">{props.score}</p>
        </div>
    );
}